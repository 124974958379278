import React from 'react';
import { IonImg, IonPage } from '@ionic/react';
import logo from './logo_cor_500.png';

const UseMobile: React.FC = () => (
  <IonPage className="h-full w-full">
    <div className="h-full w-200 flex flex-col justify-center items-center text-grey-600 text-center">
      <IonImg class="mb-4" slot="start" className="w-48" src={logo} alt="logo" />
      <div className="p-4">Desculpe, este site está disponível apenas para celular</div>
    </div>
  </IonPage>
);

export default UseMobile;
