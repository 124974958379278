import React from 'react';
import AuthContext from 'contexts/AuthContext';
import Router from 'components/shared/Router';
import apiFetch from 'helpers/apiFetch';
import { gql, useQuery } from '@apollo/client';
import Login from './Login';

const GQL = gql`
  query GetAuth {
    me {
      id
      email
      hasUnread
      profile {
        id
        activeRequest {
          id
          functionName
          startDate
          imageUrl
          centsInTotal
          uniformDescription
          job {
            id
            startDate
            endDate
            fullAddress
            accessInstructions
            city
          }
          shifts {
            id
            date
            startTime
            endTime
            inHours
            professionalCentsPerHour
            professionalCentsInTotal
          }
        }
      }
    }
  }
`;

const AuthStateApp: React.FunctionComponent = () => {
  const {
    loading, data, refetch,
  } = useQuery(GQL);

  const signOut = () => {
    apiFetch({
      method: 'DELETE',
      path: '/users/sign_out',
      skipPrefix: true,
    })
      .then(() => {
        refetch();
        // eslint-disable-next-line no-restricted-globals
        location.reload();
      });
  };

  if (loading) return <p>Loading...</p>;

  const { me: user = {} } = data || {};

  return user?.email ? (
    <AuthContext.Provider value={{ user, signOut }}>
      <Router />
    </AuthContext.Provider>
  ) : (
    <div>
      <Login afterLogin={() => refetch()} />
    </div>
  );
};

export default AuthStateApp;
