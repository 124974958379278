import React from 'react';
import ReactDOM from 'react-dom';
import { ApolloProvider, ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client';
import axios from 'axios';
import baseUrl from 'helpers/baseUrl';
import dayjs from 'dayjs';
import dayOfYear from 'dayjs/plugin/dayOfYear';
import App from './App';
import * as serviceWorker from './serviceWorker';

require('dayjs/locale/pt');

dayjs.locale('pt');
dayjs.extend(dayOfYear);

// eslint-disable-next-line @typescript-eslint/no-var-requires
const { buildAxiosFetch } = require('@lifeomic/axios-fetch');

const instance = axios.create({
  baseURL: baseUrl,
  withCredentials: true,
});

const link = createHttpLink({
  uri: '/graphql',
  fetch: buildAxiosFetch(instance),
});

const client: ApolloClient<any> = new ApolloClient({
  cache: new InMemoryCache(),
  credentials: 'same-origin',
  link,
});

ReactDOM.render(
  <ApolloProvider client={client}>
    <App />
  </ApolloProvider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
