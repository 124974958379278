import React, { useState } from 'react';
import {
  IonToolbar, IonHeader, IonImg, IonPage, IonContent, IonButton, IonToast,
} from '@ionic/react';
import logo from 'components/shared/logo-white.png';
import Input from 'components/shared/Input';
import apiFetch from 'helpers/apiFetch';

export interface ContainerProps {
  afterLogin: any;
}

const Login: React.FC<ContainerProps> = ({ afterLogin }) => {
  const [formState, setFormState]: [Record<string, any>, (d: any) => void] = useState({});
  const [toast, setToast] = useState('');

  const submit = () => {
    apiFetch({
      method: 'POST',
      path: '/users/sign_in',
      data: { user: formState },
      skipPrefix: true,
    })
      .then((data : Record<string, any>) => {
        if (data.profile) {
          afterLogin();
        } else {
          setFormState({ ...formState, password: '' });
          setToast('Email ou senha inválida');
        }
      });
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar
          style={{
            '--border-width': '0',
            '--padding-end': '0',
          }}
          class="my-header flex justify-start"
        >
          <IonImg class="ml-2" slot="start" className="h-8" src={logo} alt="logo" />
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonToast
          isOpen={!!toast}
          onDidDismiss={() => setToast('')}
          message={toast}
          duration={3000}
        />
        <form onSubmit={(e) => { e.preventDefault(); submit(); }} className="p-4 h-full">
          <div className="flex flex-col h-full">
            <div className="pt-12 h-full flex-1">
              <h1 className="text-2xl text-center text-grey-800 font-semibold">Login</h1>
              <Input
                name="email"
                label="Email"
                formState={formState}
                setFormState={setFormState}
              />
              <Input
                name="password"
                label="Password"
                type="password"
                formState={formState}
                setFormState={setFormState}
              />
            </div>
            <div>
              <IonButton type="submit" color="primary" className="w-full">Submit</IonButton>
            </div>
          </div>
        </form>
      </IonContent>
    </IonPage>
  );
};

export default Login;
