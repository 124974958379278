import { AuthInterface } from 'type-declarations/global';
import React from 'react';

const defaultAuth: AuthInterface = {
  user: undefined,
  signOut: () => {},
};
const context = React.createContext(defaultAuth);

export default context;
