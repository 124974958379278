import React from 'react';
import { IonReactRouter } from '@ionic/react-router';
import { Route } from 'react-router-dom';

const Dashboard = React.lazy(() => import('components/pages/Dashboard'));
const Profile = React.lazy(() => import('components/pages/Profile'));
const Account = React.lazy(() => import('components/pages/Account'));
const NotificationsIndex = React.lazy(() => import('components/pages/NotificationsIndex'));
const NotificationsShow = React.lazy(() => import('components/pages/NotificationsShow'));
const RequestsIndex = React.lazy(() => import('components/pages/RequestsIndex'));
const RequestsShow = React.lazy(() => import('components/pages/RequestsShow'));
const EditBankInfo = React.lazy(() => import('components/pages/EditBankInfo'));
const Redirector = React.lazy(() => import('components/pages/Redirector'));

const Router: React.FC = () => (
  <IonReactRouter>
    <Route path="/dashboard" component={Dashboard} exact />
    <Route path="/profile" component={Profile} exact />
    <Route path="/profile/edit" component={Profile} exact />
    <Route path="/requests" component={RequestsIndex} exact />
    <Route path="/requests/:id" component={RequestsShow} exact />
    <Route path="/account" component={Account} exact />
    <Route path="/account/bank-info/edit" component={EditBankInfo} exact />
    <Route path="/notifications" component={NotificationsIndex} exact />
    <Route path="/notifications/:id" component={NotificationsShow} exact />
    <Route path="/" render={() => <Redirector />} exact />
  </IonReactRouter>
);

export default Router;
