import React, { Suspense } from 'react';
import { IonApp, IonLoading } from '@ionic/react';
import Auth from 'components/pages/Auth';
import { BrowserView, MobileView } from 'react-device-detect';
import UseMobile from 'components/pages/UseMobile';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Tailwind */
import './theme/index.css';

/* Theme variables */
import './theme/variables.css';

const App: React.FC = () => (
  <IonApp>
    <BrowserView><UseMobile /></BrowserView>
    <MobileView>
      <Suspense fallback={<IonLoading isOpen />}>
        <Auth />
      </Suspense>
    </MobileView>
  </IonApp>
);

export default App;
