import React, { FC } from 'react';
import {
  IonItem, IonLabel, IonInput, IonText,
} from '@ionic/react';
import titleCase from 'helpers/titleCase';

export interface ContainerProps {
  name: string;
  label?: string;
  formState: Record<string, unknown>;
  setFormState: (d: any) => void;
  errors?: any;
  type?: 'text' | 'password' | 'number';
}

const Input: FC<ContainerProps> = ({
  name,
  label,
  formState,
  setFormState,
  errors,
  type,
}) => (
  <IonItem lines="none" style={{ '--padding-start': '0', '--inner-padding-end': '0' }}>
    <IonLabel position="stacked">{label || titleCase(name)}</IonLabel>
    <IonInput
      name={name}
      type={type || 'text'}
      value={formState[name] as string}
      onIonChange={(e: any) => setFormState({ ...formState, [name]: e.target.value })}
      className="bg-grey-200 rounded-md px-2"
      style={{ '--padding-start': '10px' }}
    />
    {errors && errors[name] && (
    <IonText color="danger" className="ion-padding-start">
      <small>
        <span role="alert" id={`${name}Error`}>
          {errors[name].message}
        </span>
      </small>
    </IonText>
    )}
  </IonItem>
);

export default Input;
