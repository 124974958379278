import axios, { AxiosPromise } from 'axios';
import baseUrl from './baseUrl';

const pathPrefix = '/api/v1';
export interface OptionProps {
  method?: 'GET' | 'POST' | 'PUT' | 'DELETE';
  path?: string;
  data?: any;
  skipPrefix?: boolean;
}

export const apiFetch = (options: OptionProps): AxiosPromise<any> => {
  const {
    method = 'GET',
    path = '',
    data,
    skipPrefix,
  } = options;

  const cleanPath = `/${path.replace(/^\/|\/$/g, '')}`;
  const url = `${baseUrl}${skipPrefix ? '' : pathPrefix}${cleanPath}`;

  return axios({
    method,
    url,
    data,
    withCredentials: true,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }).then((response) => response.data);
};

export default apiFetch;
